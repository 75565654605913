.button {
  background: #0f8dc4;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  color: #fff;
  padding: 19px;
}

.button:hover {
  cursor: pointer;
}
