.opt-logo {
  fill: #fff;
  margin-bottom: 50px;
}

.garmin-page_loading-text {
  margin-bottom: 50px;
}

.garmin-page_error-text {
  margin-bottom: 50px;
  color: red;
}

.garmin-page_success-text {
  margin-bottom: 50px;
}
